import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
// import Cards from "../components/cards"
import { StaticImage } from "gatsby-plugin-image"
import kunstImBau from "../images/downloads/KunstImBau.jpg"
import PostCard from "../components/postCard"
import "../styles/global.css"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div className="w-full md:w-4/5 flex flex-col mx-auto my-2 sm:my-8">
      <PostCard
        title="7. und 8.8.2021 - 'roh und grau - Kunst im Bau'"
        content="Gemeinschaftsausstellung 'roh und grau - Kunst im Bau' am 7. und 8.8.2021 in Marquartstein Niedernfelser Weg 3.
        "
      >
        <a href={kunstImBau} download>
          <StaticImage src="../images/posts/KunstImBau.jpg"></StaticImage>
        </a>
      </PostCard>
      <PostCard
        title="20.09.2021 - Kunstzeit Prien 2021"
        content="
        Im Rahmen des Formats Kunstzeit sind zwei Arbeiten von mir im Pavillon der Tourist-Info in Prien am Chiemsee noch bis zum 1.8.2021 zu sehen. Schaut gerne vorbei!"
      >
        <StaticImage src="../images/posts/grantler.jpg"></StaticImage>
      </PostCard>
      <PostCard
        title="01.11.2020 Kunstschaufenster in Marquartstein"
        content="Im ehemaligen Reisebüro Stöttner direkt an der Fußgängerampel in der Ortsmitte Marquartsteins sind nun einige Arbeiten von mir zu sehen. Schaut sie euch gerne an, wenn ihr vorbeikommt – ich freue mich auch über Feedback per Mail."
      >
        <StaticImage src="../images/posts/schaufenster.jpg"></StaticImage>
      </PostCard>
    </div>
  </Layout>
)

export default IndexPage
