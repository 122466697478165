import * as React from "react"

const PostCard = ({ title, subTitle, address, children, content }) => {
  return (
    <>
      <div className="md:w-7/8 rounded overflow-hidden shadow-lg m-1 mb-2 grid grid-cols-2 font-courier">
        <div className="flex flex-col justify-between">
          <div></div>
          <div className="mx-auto">{children}</div>
          <div></div>
        </div>
        <div className="md:px-4 md:py-2 h-full">
          <div className="p-2 font-bold text-xl md:mb-2">{title}</div>
          <p className="px-2">{subTitle}</p>
          <p className="px-2 pb-2">{address}</p>
          <p className="px-1 text-gray-500 text-base text-justify">{content}</p>
        </div>
      </div>
    </>
  )
}

export default PostCard
